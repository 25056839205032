import { Navigate } from 'react-router-dom';
import LoginPage from './views/login';
import StakingPage from './views/staking';
import ArbitragePage from './views/arbitrage';
import Dashboard from './views';
import AnalyzePage from './views/analyze';

export const Routes = [
    {
        path: '/login',
        element: <LoginPage />,
    },
    {
        path: '/',
        element: <Navigate to="/login" />,
    },
    {
        path: '/dashboard',
        element: <Dashboard />,
    },
    {
        path: '/analyze/:coin',
        element: <AnalyzePage />,
    },
    {
        path: '/staking',
        element: <StakingPage />,
    },
    {
        path: '/arbitrage',
        element: <ArbitragePage />,
    },
]