const LoginDetails = [
    {
        username: 'chinyere',
        password: 'vFG;@EkD}c2~Y+)',
    },
    {
        username: 'guest',
        password: 'guestPa$$word',
    },
]

/**
 * A fake authentication library
 * @param {*} username 
 * @param {*} password 
 * @returns 
 */
export const loginUser = async (username, password) => {
    return new Promise((resolve, reject) => {
        return setTimeout(() => {
        if (LoginDetails.find((user) => user.username === username && user.password === password)) {
            sessionStorage.setItem('loggedIn', true);
            return resolve( {
                loggedIn: true,
            });
        }else { 
            return resolve({
                loggedIn: false,
            });
         }
        
    }, 2000)        
    });
};
