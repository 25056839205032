import styled from 'styled-components';

export const StyledButton = styled.button`
    padding: 1.1rem 1rem;
    border: none;
    border-radius: 0.25rem;
    font-size: 1rem;
    color: #ffffff;
    background-color: #820263;
    min-width: 80%;
    outline: none;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    &:hover {
        background-color: #1B0E15;
    }
    &:active {
        background-color: #1B0E15;
    }
`;
