import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    min-width: 30%;
    height: 7%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    justify-content: space-around;
    align-items: center;  
    flex-wrap: wrap;
    border-radius: 0.5rem;

    a {
        color: #fff;
        text-decoration: none;
        font-size: 1.02rem;
        font-weight: 600;
        padding: 0.5rem;
        border-radius: 0.5rem;
        transition: all 0.2s ease-in-out;
        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }
`;
