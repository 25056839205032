import React from 'react';
import { analyzeEntry, analyzeRSI, formatCoinGeckoOHLCData } from '../../libraries/analysis';
import { toBase64 } from '../../libraries/base64';
import { commaSeparatedPrice } from '../../libraries/price';
import { CoinBreakdownContainer, CoinContainer, CoinListContainer, DetailsContainer, FlexContainer } from './styles';

export default function RenderCoins({coinData = []}) {
    return (
        <CoinListContainer>
        {coinData.map((coin, index) => {
            return (
            <CoinContainer key={index} to={`/analyze/${toBase64(coin.id)}`}>
                <FlexContainer>
                    <h3 className='title'>{coin.name} ({coin.symbol.toUpperCase()})</h3>
                    <DetailsContainer>
                            <span className='change'>
                            {coin.price_change_percentage_24h > 0 ? (
                                <span style={{ color: 'green' }}>
                                +{coin.price_change_percentage_24h}%
                                </span>
                            ) : (
                                <span style={{ color: 'red' }}>
                                {coin.price_change_percentage_24h}%
                                </span>
                            )}
                            </span>
                        <p className='price'>${commaSeparatedPrice(coin.current_price)}</p>
                    </DetailsContainer>
                </FlexContainer>
                <div className='img-container'>
                    <img src={coin.image} alt={coin.name} />
                </div>
            </CoinContainer>
            );
        })}
        </CoinListContainer>
    );
}


export const CoinBreakdown = ({ coinData, priceHistory = [] }) => {
    const priceData = formatCoinGeckoOHLCData(priceHistory);
    console.log(analyzeRSI( priceData));

    return (
        <CoinBreakdownContainer>
            <div>
                <h1>
                    {coinData.symbol.toUpperCase()} (
                    <span className='change'>
                            {coinData.market_data.price_change_percentage_24h > 0 ? (
                                <span style={{ color: 'green' }}>
                                +{coinData.market_data.price_change_percentage_24h}%
                                </span>
                            ) : (
                                <span style={{ color: 'red' }}>
                                {coinData.market_data.price_change_percentage_24h}%
                                </span>
                            )}
                    </span>)
                </h1>
                <div className='img-container'>
                    <img src={coinData.image.large} alt={coinData.symbol}/>
                </div>
                <h3>Price</h3>
                <p>${commaSeparatedPrice(coinData.market_data.current_price.usd)}</p>
                <h3>Market Cap</h3>
                <p>${commaSeparatedPrice(coinData.market_data.market_cap.usd)}
                  <span> | Rank: {coinData.market_data.market_cap_rank} </span></p>
                <h3>24h High</h3>
                <p>${commaSeparatedPrice(coinData.market_data.high_24h.usd)}</p>
                <h3>24h Low</h3>
                <p>${commaSeparatedPrice(coinData.market_data.low_24h.usd)}</p>
            </div>
            <div>
                <h3>
                    Description
                </h3>
                <div className='description' dangerouslySetInnerHTML={{__html: coinData.description.en}}></div>
            </div>
            <div>
                <h3>
                    Trade Suggestion
                </h3>
                <p>
                    {analyzeEntry(priceData)}
                </p>
                
            </div>
        </CoinBreakdownContainer>
    );
}