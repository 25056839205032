import { Container } from './styles';
import {Link} from 'react-router-dom';


export const FloatingMenu =  () => {
    return (
        <Container>            
            <Link to="/">Log Out</Link>
            <Link to="/staking">Staking</Link>
        </Container>
    )
}