import React from 'react';
import { Container } from './styles';
import { getCoinList } from '../apis/coingecko';
import RenderCoins from '../components/coin';
import { FloatingMenu } from '../components/menu';

export default function Dashboard() {
    // Renders the list of coins on the dashboard
    const [coinData, setCoinData] = React.useState([]);
    React.useEffect(() => {
        const fetchCoinData = async () => {
            const data = await getCoinList();
            setCoinData(data);
        };
        fetchCoinData();
    }, []);


    return (
        <Container>
            <Container>
                <FloatingMenu/>
                <h2>Here's  The Top 100</h2>
                 <RenderCoins coinData={coinData} />
            </Container>
        </Container>
    );
}
