import React from 'react';
import { useParams } from 'react-router-dom';
import { getCoinDetails, getCoinPriceHistory } from '../apis/coingecko';
import { CoinBreakdown } from '../components/coin';
import { fromBase64 } from '../libraries/base64';
import { Container } from './styles';

export default function AnalyzePage() {
    const { coin } = useParams();
    const coinName = fromBase64(coin);
    const [coinDetails, setCoinDetails] = React.useState(null);
    const [coinPriceHistory, setCoinPriceHistory] = React.useState(null);

    React.useEffect(() => {
        const fetchCoinDetails = async () => {
            const coinDetails = await getCoinDetails(coinName);
            setCoinDetails(coinDetails);
        };
        fetchCoinDetails();
    }, [coinName]);

    React.useEffect(() => {
        const fetchCoinPriceHistory = async () => {
            const coinPriceHistory = await getCoinPriceHistory(coinName);
            setCoinPriceHistory(coinPriceHistory);
        };
        fetchCoinPriceHistory();
    }, [coinName]);

    return (
        <Container>
            {coinDetails && (
                <CoinBreakdown coinData={coinDetails} priceHistory={coinPriceHistory} />
            )}
        </Container>
    );
}
