/**
 * Format price with comma separated thousands
 * @param {*} price 
 * @returns 
 */
export const commaSeparatedPrice = (price) => {
    if (!price) {
        return 0;
    }
    if (price < 1000) {
        return price;
    }
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

}