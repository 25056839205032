import React from 'react';
import { Button } from '../buttons';
import { Form } from '../forms';
import { InputField } from '../inputs';
import { Container } from './styles/staking.styles';


export default function StakingCalculator() {
    const [state, setState] = React.useState({
        pricePerCoin: 0,
        amountInvestedinUSD: 0,
        interestRate: 0,
        duration: 0,
        total: 0
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
       const {amountInvestedinUSD , interestRate, duration, pricePerCoin} = state;
         const total = amountInvestedinUSD * (1 + interestRate / 100) ** duration / pricePerCoin;
        setState({ ...state, total });


       
    }

    return (
        <Container>
            <h1>
                Staking Calculator
            </h1>
            <Form onSubmit={handleSubmit}>
                <InputField label="Amount Invested" name="amountInvestedinUSD" id="amountInvestedinUSD" value={state.amountInvestedinUSD} onChange={handleChange} />
                <InputField label="Interest Rate in Percentage" name="interestRate" id="interestRate" value={state.interestRate} onChange={handleChange} />
                <InputField label="Duration" name="duration" id="duration" value={state.duration} onChange={handleChange} />
                <InputField label="Price Per Coin" name="pricePerCoin" id="pricePerCoin" value={state.pricePerCoin} onChange={handleChange} />
                <Button type="submit">Calculate</Button>
            </Form>
            <h1>Total Returns: {state.total} USD</h1>
        </Container>
    );

}
