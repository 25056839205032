/**
 * Converts a piece of text to base64
 * @param {String} text 
 * @returns 
 */
export const toBase64 = (text) => {
    return btoa(text);
}
/**
 * Converts a base64 string back to it's original value
 * @param {String} value 
 * @returns 
 */
export const fromBase64 = (value) => {
    return atob(value);
}