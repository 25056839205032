import { COINGECKO_BASE_URL } from "./base";
import axios from "axios";

/**
 * Gets the list of coins from CoinGecko
 * @param {*} count 
 * @returns 
 */
export const getCoinList = async (count = 100) => {
    // https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=50&page=1&sparkline=true&price_change_percentage=24h
    const url = `${COINGECKO_BASE_URL}/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=${count}&page=1&sparkline=false&price_change_percentage=24h`;
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (err) {
        console.error(err);
    }
    return [];
}

/**
 * Gets the details of a coin from CoinGecko
 * @param {*} id 
 * @returns 
 */
export const getCoinDetails = async (id) => {
    // https://api.coingecko.com/api/v3/coins/bitcoin
    const url = `${COINGECKO_BASE_URL}/coins/${id}`;
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (err) {
        console.error(err);
    }
    return null;
}

/**
 * Gets the list of trending coins from CoinGecko
 * @returns {Array}
 */
export const getTrendingCoins = async () => {
    // https://api.coingecko.com/api/v3/search/trending
    const url = `${COINGECKO_BASE_URL}/search/trending`;
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (err) {
        console.error(err);
    }
    return [];
}


/**
 * Gets the status of the CoinGecko servers
 * @returns {Boolean}
 */
export const getServerStatus = async () => {
    // https://api.coingecko.com/api/v3/ping
    const url = `${COINGECKO_BASE_URL}/ping`;
    try {
        const response = await axios.get(url);
        return response.data;
    } catch (err) {
        console.error(err);
    }
    return false;
}

// Get price history of a coin as OHLC Values
export const getCoinPriceHistory = async (id, days = 30) => {
 
    // https://api.coingecko.com/api/v3/coins/ethereum/ohlc?vs_currency=usd&days=30
    const url = `${COINGECKO_BASE_URL}/coins/${id}/ohlc?vs_currency=usd&days=${days}`;
    try {
        const response = await axios.get(url);
        return response.data;
    }
    catch (err) {
        console.error(err);
    }
    return [];
}
