import React from 'react';
import StakingCalculator from '../components/calculators/staking';
import { Container } from './styles';

export default function StakingPage() {
  return (
    <Container>
      <StakingCalculator/>
    </Container>
  );
}
