import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const CoinListContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));  
    width: 80%;
    gap: 1rem;
    padding: 1rem;
`;

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    `;

export const CoinContainer = styled(Link)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-decoration: none;
    color: #e0c8d5;
    border: 2px solid #2b1722;
    border-radius: 15px;
    padding: 1.5rem;
    transition: all 0.3s ease-in-out;
    min-width: 200px;

    h3.title {
        font-size: 1.25rem;
        font-weight: 600;
    }

    div.img-container {
        max-width: 100px;
        object-fit: cover;
        img {
            width: 100%;
        }
    }
    &:hover {
        background-color: #2b1722;
        border: 2px solid #d90368ff;
        color: #fff;
    }
    p.price{
        font-size: 1.05rem;
        font-weight: 500;
    }

    .change {
        font-size: 0.7rem;
        font-weight: 400;
    }
    `;

export const CoinBreakdownContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 1rem;
    font-size: 0.8rem;
    text-align: justify;
    text-decoration: none;
    color: #e0c8d5;
    border: 2px solid #2b1722;
    border-radius: 15px;
    padding: 1.5rem;
    transition: all 0.3s ease-in-out;
    min-width: 80%;
    min-height: 250px;

    div.img-container {
        max-width: 100px;
        object-fit: cover;
        img {
            width: 100%;
        }
    }
    div.description{
        a{
            text-decoration: none;
            color: #fff;
        }
    }
    `;