import styled from 'styled-components';


export const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    width: 80%;
`;

export const StyledInput = styled.input`
    padding: 0.5rem;
    font-size: 1rem;
    color: #222222;
    background-color: #FD87BE;
    border: none;
    border-radius: 0.25rem;
    outline: none;
    transition: all 0.3s ease-in-out;
    border-bottom: 2px solid #D90368;

    &:focus {
        border-bottom: 2px solid #F75C03;
    }
  
`;

export const StyledLabel = styled.label`
    color: #222222;
    font-size: 0.85rem;

`;