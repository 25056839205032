import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Routes } from './routes';

const route = createBrowserRouter(Routes);

/**
 * Defines all the App routes
 */
function App () {
  return <RouterProvider router = {route}/>
}


export default App;
