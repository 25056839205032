import styled from 'styled-components';

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    max-width: 500px;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    min-height: 300px;
    box-shadow: 13px 8px 35px 0px rgba(217, 3, 104, 0.3);

`;
