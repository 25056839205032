import React from 'react';
import { InputContainer, StyledInput, StyledLabel } from './styles';

/**
 *  Input Field
 * @param {JSX.Element} label
 * @returns 
 */

export function InputField({label, ...props}) {

    return (
    <InputContainer>
        <StyledLabel htmlFor={props.id}>{label}</StyledLabel>
        <StyledInput {...props} />
    </InputContainer>
  );
}
