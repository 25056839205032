import React from 'react';
import {useNavigate} from 'react-router-dom';
import { Button } from '../components/buttons';
import { Form } from '../components/forms';
import { InputField } from '../components/inputs';
import { Container } from './styles';
import {useFormik} from 'formik';
import { loginUser } from '../libraries/authentication';
import { showMessage, MessageTypes } from '../libraries/toast';

export default function LoginPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const formHandler = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: async (values) => {
      setLoading(true);
      const { username, password } = values;
      const response = await loginUser(username, password);
      if (response.loggedIn) {
        showMessage(MessageTypes.SUCCESS, 'Login Successful');
        navigate('/dashboard');
      } else {
        showMessage(MessageTypes.ERROR, 'Invalid Credentials');
      }
      setLoading(false);
    },
  });

  return (
    <Container>
    <h1>Welcome Back, Chinyere</h1>
    <Form onSubmit={formHandler.handleSubmit}>
      <InputField label="Username" type="text" name="username" required  onChange={formHandler.handleChange} value={formHandler.values.username}/>
      <InputField label="Password" type="password" name="password" onChange={formHandler.handleChange} value={formHandler.values.password} required/>
      <Button type="submit" disabled={loading}>{
        loading ? 'Logging In...' : 'Login'
      }</Button>
    </Form>
    </Container>
  );
}
