import { SMA, RSI } from '@debut/indicators';

// Analyze candles for liquidity gaps
export function analyzeLiquidityGaps(candles) {
    var liquidityGaps = [];
    for (var i = 0; i < candles.length - 1; i++) {
        var liquidityGap = candles[i].volume - candles[i + 1].volume;
        liquidityGaps.push(liquidityGap);
    }
    return liquidityGaps;
}

// Analyze candles for fair value gaps
export function analyzeFairValueGaps(candles) {
    var fairValueGaps = [];
    for (var i = 0; i < candles.length - 1; i++) {
        var fairValueGap = candles[i].close - candles[i].open;
        fairValueGaps.push(fairValueGap);
    }
    return fairValueGaps;
}

/**
 * Analyze candles for SMA
 * @param {*} candles 
 * @param {Number} period
 */
export function analyzeSMA(candles, period = 20) {
    const sma = new SMA(period);
    const smaValues = [];
    for (var i = 0; i < candles.length; i++) {
        smaValues.push(sma.nextValue(candles[i].close));
    }
    return smaValues;
}

/**
    * Analyze candles for RSI
    * @param {*} candles
    * @param {Number} period
    * @returns
    * 0 - oversold
    * 1 - overbought
    * 2 - neutral
    * 3 - no data
    * 4 - error
    */
export function analyzeRSI(candles, period = 14) {
    const rsi = new RSI(period);
    const rsiValues = [];
    for (var i = 0; i < candles.length; i++) {
        rsiValues.push(rsi.nextValue(candles[i].close));
    }
    return rsiValues;
}

/**
 * Format CoinGecko OHLC data
 * @param {Array} data 
 * @returns 
 */
export const formatCoinGeckoOHLCData = (data) => {
    const formattedData = [];
    if(!data) return formattedData;
    data.forEach((d) => {
        formattedData.push({
            time: new Date(d[0]),
            open: d[1],
            high: d[2],
            low: d[3],
            close: d[4],
        });
    });
    return formattedData;
}

// Analyzes the candles using the results of RSI and SMA and gives a buy or sell signal
export const analyzeEntry = (candles, period = 14) => {

    const rsiValues = analyzeRSI(candles, period);
    const smaValues = analyzeSMA(candles, period);

    //  Get the aggregate of the last 3 RSI values and SMA values and If the aggregate RSI is above 70 and the SMA is below the current price, it's a sell signal
    const rsiAggregate = rsiValues[rsiValues.length - 1] + rsiValues[rsiValues.length - 2] + rsiValues[rsiValues.length - 3];
    const smaAggregate = smaValues[smaValues.length - 1] + smaValues[smaValues.length - 2] + smaValues[smaValues.length - 3];

    if (rsiAggregate > 70 && smaAggregate < candles[candles.length - 1].close) {
        return 'SELL';
    }

    // If the RSI is below 30 and the SMA is above the current price, it's a buy signal
    if (rsiAggregate < 30 && smaAggregate > candles[candles.length - 1].close) {
        return 'BUY';
    }

    return 'NEUTRAL - Sit On Your Hands';
  
}